





















































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import { AppModule } from '@/store/modules/app';
import Header from './components/Header.vue';
import RegistForm from './components/RegistForm.vue';
import common from '@/utils/common';
import { domainConfig } from '@/config';
import SimpleForm from '../EGGAlai/components/SimpleForm.vue';

const sourceMap = process.env.NODE_ENV ? process.env.NODE_ENV : 'dev';

@Component({
    name: 'HomePage',
    components: {
        Header,
        // Footer,
        RegistForm,
        SimpleForm
    }
})
export default class extends Vue {
    $i18n: any
    $router: any
    $alert: any
    $refs: any

    autoplay = true

    introVideoUrl = ''

    contentVideoUrl = ''

    sourceMap = sourceMap

    private h5Config:any = {
        // 开发环境
        development: {
            // url: 'http://localhost:8088/'
            url: 'https://image-cdn.jimuitech.com/repair/official_web/introduce_video/'
        },
        dev: {
            // url: 'http://localhost:8088/'
            url: 'https://image-cdn.jimuitech.com/repair/official_web/introduce_video/'
        },
        // 测试环境
        test: {
            url: 'https://image-cdn.jimuitech.com/repair/official_web/introduce_video/'
        },
        // uat环境
        uat: {
            url: 'https://image-cdn.jimuitech.com/repair/official_web/introduce_video/'
        },
        // stable环境
        stable: {
            url: 'https://image-cdn.jimuitech.com/repair/official_web/introduce_video/'
        },
        // 线上环境
        production: {
            url: 'https://image-cdn.eggmalltech.com/repair/official_web/introduce_video/'
        }
    }

    @Watch('$i18n.locale')
    watchLocale(newVal:any) {
        this.switchVideo(newVal);
    }

    mounted() {
        common.seoOptimize({
            title: 'EGG Repair workshop management system',
            keywords: 'Garage management, workshop management system, mechanics management, Car repair system, workshop dashboard',
            description: 'EGG Repair is a complete cloud system for workshop which can help workshop owners manage their shop easily. Register and start your free trial!'
        });
        this.switchVideo(this.$i18n.locale);
        this.listenVideo();
    }

    switchVideo(val:any) {
        switch (val) {
            case 'th-TH':
                this.introVideoUrl = `${this.h5Config[sourceMap].url}EGGRepair_TH.mp4`;
                this.contentVideoUrl = `${this.h5Config[sourceMap].url}SCP_TH2.mp4`;
                break;
            case 'id':
                this.introVideoUrl = `${this.h5Config[sourceMap].url}EGGRepair_ID.mp4`;
                this.contentVideoUrl = `${this.h5Config[sourceMap].url}SCP_EN2.mp4`;
                break;
            default:
                this.introVideoUrl = `${this.h5Config[sourceMap].url}EGGRepair_EN.mp4`;
                this.contentVideoUrl = `${this.h5Config[sourceMap].url}SCP_EN2.mp4`;
        }
    }

    getIndex(nowIndex:any, oldIndex:any) {
        // 用户可能在上个视频未播放完毕就切换
        // 此时需要暂停上个视频并把时间归零，否则影响对当前视频的监听逻辑
        if (nowIndex > 0) {
            const myVideo:any = this.$refs.videoPlayer;
            myVideo.pause();
            // myVideo.currentTime = 0;
            this.listenVideo();
            this.autoplay = true;
        }
    }

    listenVideo() {
        const myVideo:any = this.$refs.videoPlayer;
        const scpVideo:any = this.$refs.scpVideo;
        myVideo.addEventListener('play', () => {
            this.autoplay = false;
        });
        myVideo.addEventListener('ended', () => {
            myVideo.load();
        });
        scpVideo.addEventListener('ended', () => {
            scpVideo.load();
        });
    }

    openRepairWeb() {
        (this as any).saveEventTracking('272');
        window.open(`${domainConfig.repair}#/home/otp?langCode=${AppModule.locale}&fromOfficialLogin=true`);
    }
}
